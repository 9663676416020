<template>
  <div>
    <div v-for="(item, key) in items" :key="key" class="mb-5">
      <div class="text-color-title body-1 font-weight-bold mb-3">{{ item.name }}</div>
      <div v-for="(badge, badgeKey) in item.items" :key="badgeKey" >
        <div class="d-flex justify-space-between align-center font-weight-bold">
          <div class="d-flex align-center">
            <div>
              <img contain width="40px" :src="badge.image_url" />
            </div>
            <p class="ml-4 mb-0">{{ badge.description }}</p>
          </div>
          <p class="text-right mb-0">{{ badge.date_acquired | MIXINS_DATE}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>
